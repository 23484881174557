// export const state = () => ({
//     locale: 'en',
// });

// export const actions = {
// };

// export const mutations = {
// };

export const state = () => ({
    locale: "en",
    auth: {
        loggedIn: false,
        userDetails: null,
    },
});

export const mutations = {
    SET_USER_DETAILS(state, details) {
        state.auth.userDetails = details;
    },
    SET_LOGGED_IN(state, value) {
        state.auth.loggedIn = value;
    },
};

export const actions = {
    async fetchUserDetails({ commit }) {
        try {
            const response = await this.$axios.$get("/auth/user/settings");
            commit("SET_USER_DETAILS", response);
            return response;
        } catch (error) {
            console.error("Error fetching user details:", error);
            return null;
        }
    },
};
