import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f137c228 = () => interopDefault(import('../pages/code/index.vue' /* webpackChunkName: "pages/code/index" */))
const _55febab4 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _c84044c0 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _2a0c297a = () => interopDefault(import('../pages/company/store.vue' /* webpackChunkName: "pages/company/store" */))
const _27217366 = () => interopDefault(import('../pages/register/setup.vue' /* webpackChunkName: "pages/register/setup" */))
const _0db36e8e = () => interopDefault(import('../pages/reset/forgot-password.vue' /* webpackChunkName: "pages/reset/forgot-password" */))
const _84f1a7c4 = () => interopDefault(import('../pages/reset/resetpassword.vue' /* webpackChunkName: "pages/reset/resetpassword" */))
const _a0da074a = () => interopDefault(import('../pages/user/settings.vue' /* webpackChunkName: "pages/user/settings" */))
const _2e1a85f4 = () => interopDefault(import('../pages/user/usermanagement.vue' /* webpackChunkName: "pages/user/usermanagement" */))
const _7fa3da1c = () => interopDefault(import('../pages/company/_id.vue' /* webpackChunkName: "pages/company/_id" */))
const _490e8dc6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/code",
    component: _f137c228,
    name: "code"
  }, {
    path: "/company",
    component: _55febab4,
    name: "company"
  }, {
    path: "/login",
    component: _c84044c0,
    name: "login"
  }, {
    path: "/company/store",
    component: _2a0c297a,
    name: "company-store"
  }, {
    path: "/register/setup",
    component: _27217366,
    name: "register-setup"
  }, {
    path: "/reset/forgot-password",
    component: _0db36e8e,
    name: "reset-forgot-password"
  }, {
    path: "/reset/resetpassword",
    component: _84f1a7c4,
    name: "reset-resetpassword"
  }, {
    path: "/user/settings",
    component: _a0da074a,
    name: "user-settings"
  }, {
    path: "/user/usermanagement",
    component: _2e1a85f4,
    name: "user-usermanagement"
  }, {
    path: "/company/:id",
    component: _7fa3da1c,
    name: "company-id"
  }, {
    path: "/",
    component: _490e8dc6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
