export default function ({ store, redirect, route }) {
    // Make sure we capture the full path including query parameters
    const currentPath = route.path;

    // Define public paths (paths that don't require authentication)
    const publicPaths = [
        "/login",
        "/reset/resetpassword",
        "/reset/forgot-password",
    ];

    // Check if the current path matches any public path
    const isPublicPath = publicPaths.some((path) =>
        currentPath.startsWith(path)
    );

    // If user is not logged in AND this isn't a public path, redirect to login
    if (!store.state.auth.loggedIn && !isPublicPath) {
        return redirect("/login");
    }

    return true;
}
