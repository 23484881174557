export default {
    endpoints: {
        companies: "companies",
        csv: "csv",
        export: "export",
        industries: "industries",
        login: "auth/login",
        register: "auth/register",
        generateTwoFactor: "auth/generate-2fa",
        verifyTwoFactor: "auth/verify-2fa",
        userSettings: "auth/user/settings",
        disable2FA: "auth/disable-2fa",
        reports: "reports",
        sectors: "sectors",
        tasks: "tasks",
        regions: "regions",
        query: "query",
        mcgroups: "mcgroups",
        code: "reports/code",
        patching: "patching",
    },
};
