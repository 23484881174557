//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SiteNavigation',
    data() {
        return {
            isOpen: false,
            navigation: [
                {
                    title: 'Companies',
                    link: 'company',
                },
                {
                    title: 'Report Editor',
                    link: 'code',
                },
            ]
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.loggedIn;
        }
    },
    methods: {
        async handleLogout() {
            try {
                await this.$auth.logout();
                // Clean any additional state or cookies if needed
                this.$router.push('/login');
            } catch (error) {
                console.error('Logout error:', error);
                // If logout fails, force clear auth state and redirect
                this.$auth.reset();
                this.$router.push('/login');
            }
        }
    },
    watch: {
        '$route'() {
            this.isOpen = false;
        }
    }
}
